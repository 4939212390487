<template>
	<div class="">
		<!-- 文本 -->
		<div class="fllow_form" v-if="objInfo.tagIcon == 'input'" >
			<div><span :class="objInfo.required ==true ? 'b_color' : 'bo_color'  ">*</span>{{objInfo.label}}</div>
			<div class="form_input">
			<input :type="objInfo.type?objInfo.type:'span'"  :class="objInfo.defaultValue ? 'valueActive' : '' "
			@input="changeInput"
				placeholder-style="color:#999;font-size:32px;" :value="objInfo.defaultValue"
				:placeholder="objInfo.placeholder"  :disabled="allDisabled"/>
			</div>
		</div>
		<!-- 文本域 -->
		 <div class="fllow_area" v-if="objInfo.tagIcon == 'spanarea' && (objInfo.config.required == true || !isNotRequired) && !objInfo.config.addShow">
			<div class="area_head">
				<span :class="objInfo.required == true ? 'b_color' : 'bo_color'  ">*</span>
				{{objInfo.label}}
			</div>
			<spanarea
			 :disabled="allDisabled"
				@input="changespanarea" 
				maxlength="-1" 
				:auto-height="autoHeight" 
				:style="{height:(objInfo.autosize.maxRows*50)+'px'}"
				:class="objInfo.defaultValue ? 'valueActive' : '' " 
				:value="objInfo.defaultValue ? objInfo.defaultValue : ''" 
				:placeholder="objInfo.placeholder" 
				placeholder-style="color:#999;font-size:32px;" />
		 </div>
		 <!-- 下拉选择框 -->
		 <div class="fllow_form" v-if="objInfo.tagIcon == 'select'">
		 	<div><span  :class="objInfo.required ==true ? 'b_color' : 'bo_color'  ">*</span>{{objInfo.label}}</div>
		 	<div class="form_right" @click="changeSelect">
		 		<div :class="objInfo.defaultValue.length>0 ? 'valueActive' : '' " v-if="objInfo.multiple">
		 			{{objInfo.defaultValue.length>0 ? objInfo.defaultValue : objInfo.placeholder}}
		 		</div>
				<div :class="objInfo.defaultValue ? 'valueActive' : '' " v-else>
					{{objInfo.defaultValue ? objInfo.defaultValue : objInfo.placeholder}}
				</div>
		 		<div class="iconfont icon-arrows_right"></div>
		 	</div>
		 </div>
			 <!-- <tki-tree
				ref="tkitree" 
				v-if="objInfo.tagIcon == 'select'"
				:range="objInfo.options"
				:multiple="objInfo.multiple"
				:rangeKey="'label'" 
				:idKey="'nodeKey'"
				confirmColor="#008EFF"
				@confirm="selectTree" 
			 /> -->
		 <!-- 单选 -->
		 <div class="fllow_form" v-if="objInfo.tagIcon == 'radio' ">
		 	<div><span  :class="objInfo.required ==true ? 'b_color' : 'bo_color'  ">*</span>{{objInfo.label}}</div>
		 	<div class="form_right">
		 		<!-- <radio-group @change="changeRadio" class="labellist"  > -->
		 			<!-- <label class="" class="labelbox" v-for="(item,index) in objInfo.options" :key="index">
		 				<div>
		 					<radio color="#008EFF"  :value="item.label"
		 						:checked="item.label == objInfo.defaultValue" :disabled="allDisabled" />
		 				</div>
		 				<div>{{item.label}}</div>
		 			</label>
		 		</radio-group> -->
		 	</div>
		 </div>
		 <!-- 多选 -->
		 
		 <div class="fllow_area" v-if="objInfo.tagIcon == 'checkbox' ">
		 	<div class="area_head"><span  :class="objInfo.required ==true ? 'b_color' : 'bo_color'  ">*</span>{{objInfo.label}}</div>
		 	<!-- <checkbox-group @change="changeCheckbox" style="display: flex;justify-content: flex-start;flex-wrap: wrap;" :value="objInfo.label"  >
		 		<label class="" style="margin-right:15px;margin-bottom:25px;display: block;" v-for="(item,index) in objInfo.options" :key="index" >
		 			<checkbox class="checkbox" color="#ff7800" :value="item.label" :checked="objInfo.defaultValue.indexOf(item.label) != -1":disabled="allDisabled" />
		 			<span>{{item.label}}</span>
		 		</label>
		 	</checkbox-group> -->
		 </div>
		 
		 <!-- 日期 -->
		 <div class="fllow_form" v-if="objInfo.tagIcon == 'date' ">
		 	<div><span  :class="objInfo.required ==true ? 'b_color' : 'bo_color'  ">*</span>{{objInfo.label}}</div>
		 	<!-- <picker @change="changeDate"  mode="date"  :disabled="allDisabled">
		 		<div class="form_right">
		 			<div :class="objInfo.value ? 'valueActive' : '' ">
		 				{{objInfo.defaultValue ? objInfo.defaultValue : objInfo.placeholder}}
		 			</div>
		 			<div class="iconfont icon-arrows_right"></div>
		 		</div>
		 	</picker> -->
		 </div>
		 <!-- 评分 -->
		 <div class="fllow_form" v-if="objInfo.tagIcon == 'rate'" >
		 	<div><span  :class="objInfo.required ==true ? 'b_color' : 'bo_color'  ">*</span>{{objInfo.label}}</div>
		 	<div class="form_right">
		 		<div>
		 			<!-- <u-rate @change="changeRate"  v-model="objInfo.defaultValue" active-color="#FFD049" inactive-color="#b2b2b2" :size="35" :gutter="10"  :disabled="allDisabled"></u-rate> -->
		 		</div>
		 	</div>
		 </div>
		 <!-- 图片上传 -->
		 
		 <!-- 上传图片 -->
		<!-- <upload-image
		 	v-if="objInfo.tagIcon =='upload'&& (objInfo['list-type']=='picture-card')"
		 	:flag="objInfo.required" 
		 	:imgList="objInfo.defaultValue?objInfo.defaultValue:[]" 
		 	@uploadImg="uploadImg" 
		 	@delImg="delImg"
		 	:title="objInfo.label"
			:isEdit="!allDisabled"
		 ></upload-image> -->
		  <!-- 上传文件 -->
		  <!-- <upload-file
		  	v-if="objInfo.tagIcon == 'upload' && (objInfo['list-type']!='picture-card')"
		  	:title="objInfo.label"
		  	:flag="objInfo.required"
		  	:fileList="objInfo.defaultValue?objInfo.defaultValue:[]"
		  	@uploadFile="uploadFile"
		  	@delFile="delFile"
			:labelName='objInfo.buttonspan'
			:isEdit="!allDisabled"
		  >
		  </upload-file>  -->
	</div>
</template>

<script>
		// import tkiTree from "@/component/tki-tree/tki-tree.vue"
		// import uploadImage from "@/component/uploadImg/index.vue"
		// import uploadFile from '@/component/uploadFile/index.vue'
	export default{
		props: {
			objInfo: {
				default: {}
			},
			allDisabled:{
				default:false
			}
			},
		data(){
			return {
				form:{},
			
			}
		},
		methods:{
			changeInput(e) {
				let name = this.objInfo.vModel
				let value = e.detail.value
				this.$emit('changeData', name, value, this.objInfo)
			},
			changespanarea(e) {
				let name = this.objInfo.vModel
				let value = e.detail.value
				this.$emit('changeData', name, value, this.objInfo)
			},
			changeSelect() {
				if(this.allDisabled){
					return
				}
					this.$refs.tkitree._show()

			},
			selectTree(arr) {
				console.log(arr,'选择')
				let name = this.objInfo.vModel
				if(arr.length > 0) {
					let value = []
					arr.forEach(ele=>{
						value.push(ele.label)
					})
					this.$emit('changeData', name, value.join(','), this.objInfo)
				}else{
					this.$emit('changeData', name, '', this.objInfo)
				}
			},
			changeRadio(e) {
				let value = e.detail.value
				let name = this.objInfo.vModel
				this.$emit('changeData', name, value, this.objInfo)
			},
			changeCheckbox(e) {
				let name = this.objInfo.vModel
				let value = e.detail.value
				this.$emit('changeData', name, value.join(','), this.objInfo)
			},
			changeDate(e) {
				let date = e.detail.value
				let name = this.objInfo.vModel
				this.$emit('changeData', name, date, this.objInfo)
			},
			changeRate(e) {
				let value = e
				let name = this.objInfo.vModel
				this.$emit('changeData', name, value, this.objInfo)
			},
			//图片
			uploadImg(obj){
				let name = this.objInfo.vModel
				let arr = this.objInfo.defaultValue?this.objInfo.defaultValue:[]
				arr.push(obj)
				this.$emit('changeData', name, arr, this.objInfo)
			},
			delImg(index) {
				let name = this.objInfo.vModel
				let arr = this.objInfo.defaultValue
				arr.splice(index,1)
				this.$emit('changeData', name, arr, this.objInfo)
			},
			//文件
			uploadFile(obj) {
				let name = this.objInfo.vModel
				let arr = this.objInfo.defaultValue?this.objInfo.defaultValue:[]
				arr.push(obj)
				this.$emit('changeData', name, arr, this.objInfo)
			},
			delFile(index) {
				let name = this.objInfo.vModel
				let arr = this.objInfo.defaultValue
				arr.splice(index,1)
				this.$emit('changeData', name, arr, this.objInfo)
			}
		},
		components: {
			// tkiTree,
			// uploadImage,
			// uploadFile
		},
	}
</script>

<style lang="scss" scoped>
	.b_color{
		color: #f00;
	}
	.bo_color{
		color: #ffffff;
	}
	.valueActive {
		color: #333;
	}

	.labellist {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		.labelbox {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}
	}

	radio {
		transform: scale(0.6);
	}

	.sales_item {
		display: flex;
		justify-content: flex-start;
		padding-bottom: 15px;
		flex-wrap: wrap;

		.checkbox {
			display: flex;
			justify-content: flex-start;
			margin-right: 20px;
			margin-bottom: 20px;
		}
	}

	.u-checkbox__icon {
		width: 20px;
		height: 20px;
	}
</style>
<style lang="scss" scoped>
	.b_color{
		color: red;
	}
	.fllow_form {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: #fff;
		border-bottom: 1px solid #EAEAEA;
		padding: 30px 20px;
		color: #999;
		font-size: 32px;
		:first-child {
			flex-shrink: 0;
			font-weight: 32px;
		}

		.form_right {
			display: flex;
			align-items: center;
			justify-content:flex-end;
			flex-wrap: wrap;
			color: #999;
			span-align: right;
			font-weight: 32px;
		}

		.theme {
			color: #5ca9fe;
		}

		span {
			// color: #f00;
			margin-left: 10px;
		}

		.form_input {
			input {
				span-align: right;
				margin-right: 10px;
				
			}
			.valueActive {
				word-break: break-all;
				margin-left: 20px;
			}
		}
	}

	.fllow_area {
		background-color: #fff;
		padding: 30px 20px;
		padding-bottom: 30px;
		color: #999;
		font-size: 32px;
		.area_head {
			margin-bottom:24px;
		}
		spanarea {
		    width: 80%;
		    height: 300px;
		    padding: 30px;
		    background: #f8f8f8;
		    margin: 0 auto;
		}
	}
</style>