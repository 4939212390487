<template>
  <div>
       <el-upload 
            action="/api/system/upload/uploadImage"
            list-type="text"
             class="upload-demo"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success='handleSuccess'
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
             <!-- <el-upload
            v-if="item.tag == 'el-upload' && item['list-type'] == 'text'"
            class="upload-demo"
            action="http://192.168.31.182:8080/system/upload/uploadImage"
            multiple
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload> -->
  </div>
</template>

<script>
export default {
    props:['obj'],
    data(){
        return {
              //上传
      dialogImageUrl: "",
      dialogVisible: false,
        }
    },
    methods:{
     handleRemove(file, fileList) {
        console.log(file, fileList);
        let arr =[]
        fileList.forEach(ele => {
            arr.push(ele.response.data)
        });
          this.$emit('delImage',this.obj,arr)
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleSuccess(res){
        this.$emit('uploadImage',this.obj,res.data)
      }
    }
}
</script>

<style scoped lang='scss'>
::v-deep .el-upload-list__item-name{
  text-align: left;
}
</style>