import axios from 'axios'

// 创建 axios 实例
const service = axios.create({
    // baseURL: 'http://192.168.31.182:8080',
    timeout: 5000 // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在这里可以添加一些请求前的逻辑，比如设置 token
        // config.headers['X-Token'] = 'your-token'
        return config
    },
    error => {
        // 处理请求错误
        console.error('Request Error:', error) // for debug
        Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 在这里可以添加一些响应前的逻辑
        const res = response.data
        // 如果自定义的返回状态码不是成功标识，可以在这里统一处理
        // if (res.code !== 20000) {
        //   // 处理错误
        // }
        return res
    },
    error => {
        // 处理响应错误
        console.error('Response Error:', error) // for debug
        return Promise.reject(error)
    }
)

export default service
