<!--
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2024-03-13 11:35:35
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2024-03-16 10:16:04
 * @FilePath: \53-yiliaoheguiScanQRCode\src\components\uploadImg.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2024-03-13 11:35:35
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2024-03-16 09:41:37
 * @FilePath: \53-yiliaoheguiScanQRCode\src\components\uploadImg.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div>
       <!-- <el-upload 
            action="/api/system/upload/uploadImage"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-success='handleSuccess'
            capture="camera"
            accept="image/*"
          >
            <i class="el-icon-plus"></i>
          </el-upload> -->
          <van-uploader :after-read="afterRead"  capture='camera' v-model="fileList" @delete="delImg"/>
          
  </div>
</template>

<script>
export default {
    props:['obj'],
    data(){
        return {
              //上传图片
      dialogImageUrl: "",
      dialogVisible: false,
      file:"",
      fileList:[],
      fileListMy:[],
        }
    },
    methods:{
      afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      const forms = new FormData();
      //这里的file表示给后台传的属性名字，这里看接口文档需要传的的属性
      forms.append("file", file.file); // 获取上传图片信息
      console.log(forms)
      this.$api.uploadImage(forms).then(res=>{
        console.log(res)
        if(res.code==200){
          this.fileListMy.push(res.data)
          this.handleSuccess(res)
          // this.fileList.push({'url':res.data.newName})
        }
      })
      // this.file=file
      // alert(JSON.stringify(file))
    },
     handleRemove(file, fileList) {
        console.log(file, fileList);
        let arr =[]
        fileList.forEach(ele => {
            arr.push(ele.response.data)
        });
          this.$emit('delImage',this.obj,arr)
      },
      handlePictureCardPreview(file) {

        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      handleSuccess(res){
        this.$emit('uploadImage',this.obj,res.data)
      },
      delImg(v,detail){
          console.log(v,detail)
          // console.log(this.fileList)
          this.fileListMy.splice(detail.index, 1);
          // console.log(this.fileListMy)
          this.$emit('delImage',this.obj,this.fileListMy)
      }
    }
}
</script>

<style>

</style>