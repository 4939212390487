/*
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2024-03-13 11:35:35
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2024-03-18 09:03:23
 * @FilePath: \53-yiliaoheguiScanQRCode\src\router\router.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import MyPage from '@/components/ScanQRCode.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        // component:()=>import('../components/mapName')
        name: 'home',
        component: MyPage
    },
    {
        path:'/mySuccess',
        component:()=>import('../components/mySuccess')
    },
    {
        path:'/myError',
        component:()=>import('../components/myError')
    },
    {
        path:'/mapName',
        component:()=>import('../components/mapName')
    }
]

const router = new VueRouter({
    mode: 'history', // 去掉url中的#
    routes
})

export default router
