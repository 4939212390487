<template>
  <div>
    <el-checkbox-group v-model="checkList" @change='change'>
      <el-checkbox
        :label="item.label"
        v-for="(item, i) in obj.options"
        :key="i"
      ></el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  props: ["obj"],
  data() {
    return {
      checkList: [],
    };
  },
  methods:{
    change(){
        this.$emit('handelCheck',this.obj,this.checkList)
    }
  }
};
</script>

<style>
</style>