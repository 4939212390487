<!--
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2024-03-13 11:35:35
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2024-03-28 15:46:41
 * @FilePath: \53-yiliaoheguiScanQRCode\src\components\ScanQRCode.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
 <template>
  <div>
    <!-- <div style="color: red">templateId：{{ templateId }}</div>
    <div style="color: red">selected：{{ selected }}</div>
    <div style="color: red">taskId:{{ taskId }}</div>
    <div style="color: red">userId:{{ userId }}</div>
    <div style="color: red">taskCategory:{{ taskCategory }}</div> -->
    <!-- <div style="color: red">{{ ip }}</div> -->

    <div class="templateName">
      {{ dataList.templateName }}
      <!-- {{ this.address2 }} -->
    </div>
    <!-- <div>
      {{ this.lat }}
      {{ this.lon }}
    </div> -->

    <div class="myform">
      <el-form>
        <el-form-item label="姓名" required>
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="电话号" required>
          <el-input v-model="phone"></el-input>
        </el-form-item>
        <el-form-item label="当前位置" required>
          <!-- <el-button @click="getCurLocation" type="primary" size="mini" style="position: absolute; top: 7px;left: 89px;">点击获取</el-button> -->
          <el-input
            v-model="address"
            style="display: inline-block"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          v-for="(item, index) in formItems"
          :key="index"
          :label="item.label"
          :required="item.required"
        >
          <component
            :is="item.tag"
            v-model="form[item.vModel]"
            v-bind="getComponentProps(item)"
            :disabled="disabled"
            v-if="item.tag !== 'el-upload' && item.tag != 'el-checkbox-group'"
          >
            <template v-if="item.tag == 'el-radio-group'">
              <el-radio
                v-model="form[item.vModel]"
                :label="ele.label"
                v-for="ele in item.options"
                :key="ele.label"
                >{{ ele.label }}</el-radio
              >
            </template>
            <template v-if="item.tag == 'el-select'">
              <el-option
                :label="ele.label"
                :value="ele.label"
                v-for="ele in item.options"
                :key="ele.label"
                >{{ ele.label }}</el-option
              >
            </template>
          </component>
          <check-box
            v-if="item.tag == 'el-checkbox-group'"
            :obj="item"
            @handelCheck="handelCheck"
          ></check-box>
          <!-- 图片 -->
          <uploadImg
            v-if="item.tag == 'el-upload' && item['list-type'] !== 'text'"
            :obj="item"
            @uploadImage="uploadImage"
            @delImage="delImage"
          ></uploadImg>
          <!-- 文件 -->
          <uploadPdf
            v-if="item.tag == 'el-upload' && item['list-type'] == 'text'"
            :obj="item"
            @uploadImage="uploadImage"
            @delImage="delImage"
          ></uploadPdf>
        </el-form-item>
      </el-form>
      <div class="btn">
        <el-button @click="submit" type="primary">提交</el-button>
      </div>
      <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <div>
          <div>{{ location.latitude }}</div>
          <div>{{ location.longitude }}</div>
        </div>
      </el-drawer>
      <van-popup v-model="showLocation" position="bottom">
        <van-picker
          title="请选择当前位置"
          show-toolbar
          :columns="columns"
          :value="title"
          @confirm="confirmLocation"
          @cancel="onCancel"
      /></van-popup>
    </div>
  </div>
</template>
<script type="text/javascript" src="https://apis.map.qq.com/tools/geolocation/min?key=GUHBZ-5GF3I-BQLGX-UL4FA-QQO6V-ELFFG&referer=myapp"></script>
<!-- <script type="text/javascript" src="https://mapapi.qq.com/web/mapComponents/geoLocation/v/geolocation.min.js"></script> -->
<script>
import { Toast } from "vant";
import myform from "@/components/myForm";
import uploadImg from "./uploadImg";
import uploadPdf from "./uploadPdf";
import CheckBox from "./checkBox";
import axios from "axios";
var geolocation = new qq.maps.Geolocation();
export default {
  name: "MyPage",
  components: {
    myform,
    uploadImg,
    uploadPdf,
    CheckBox,
  },
  data() {
    return {
      info: '[{"label":"多行文本","tag":"el-input","tagIcon":"textarea","type":"textarea","placeholder":"请输入多行文本","span":24,"labelWidth":null,"autosize":{"minRows":4,"maxRows":4},"style":{"width":"100%"},"maxlength":null,"show-word-limit":false,"readonly":false,"disabled":false,"required":true,"regList":[],"changeTag":true,"document":"https://element.eleme.cn/#/zh-CN/component/input","formId":102,"renderKey":1704677703482,"layout":"colFormItem","vModel":"field102","defaultValue":"默认文本"},{"label":"多行文本","tag":"el-input","tagIcon":"textarea","type":"textarea","placeholder":"请输入多行文本","span":24,"labelWidth":null,"autosize":{"minRows":4,"maxRows":4},"style":{"width":"100%"},"maxlength":null,"show-word-limit":false,"readonly":false,"disabled":false,"required":true,"regList":[],"changeTag":true,"document":"https://element.eleme.cn/#/zh-CN/component/input","formId":102,"renderKey":1704677703482,"layout":"colFormItem","vModel":"field102","defaultValue":"默认文本"},{"label":"多行文本","tag":"el-input","tagIcon":"textarea","type":"textarea","placeholder":"请输入多行文本","span":24,"labelWidth":null,"autosize":{"minRows":4,"maxRows":4},"style":{"width":"100%"},"maxlength":null,"show-word-limit":false,"readonly":false,"disabled":false,"required":true,"regList":[],"changeTag":true,"document":"https://element.eleme.cn/#/zh-CN/component/input","formId":102,"renderKey":1704677703482,"layout":"colFormItem","vModel":"field102","defaultValue":"默认文本"}]',
      disabled: false,
      params: "",
      result: [],
      templateId: "",
      selected: "",
      taskId: "",
      userId: "",
      name: "",
      phone: "",
      objInfo: [],
      form: {},
      ip: "",
      dataList: {},
      lat: "",
      lon: "",
      address: "",
      //
      location: [],
      pois: [],
      drawer: false,
      direction: "btt",
      showLocation: false,
      columns: [],
      address2: "",
      userAgent:"",
    };
  },
  computed: {
    formItems() {
      return JSON.parse(this.info);
    },
  },
  created() {
    console.log(this.$route);
    console.log(location);
    this.getUserAgent();
  },


  updated() {},
  mounted() {
    console.log(456);
    this.getParams();
    // this.getCurrentLocation();
    // this.getLocation1()
  },
  methods: {

    getUserAgent(){
      this.userAgent = navigator.userAgent;
      console.log("userAgent",this.userAgent)
    },
    
    confirmLocation(value, index) {
      this.address = value;
      this.showLocation = false;
    },
    // onChange(picker, value, index) {
    //   Toast(`当前值：${value}, 当前索引：${index}`);
    // },
    onCancel() {
      this.showLocation = false;
    },
    getCurLocation() {
      // console.log(88);
      // let geolocation = new qq.maps.Geolocation(
      //   "P65BZ-646CH-JLEDY-W2VMD-RJAMZ-RDFWX", //替换自己的key
      //   "myAPP"
      // );
      geolocation.getLocation(this.showPosition, this.errorPosition, {
        options: { timeout: 3000, failTipFlag: true },
      }); //开启定位
    },
    //成功返回的信息，挑取自己所需要的
    showPosition(position) {
      this.lat = position.lat;
      this.lon = position.lng;
      var location = position.lat + "," + position.lng;
      this.getLocationCN();
    },
    // 定位失败 继续尝试定位
    errorPosition(e) {
      console.log(e, "定位失败，再次进行定位");
      if(e.code&&e.code==5){
        alert('您的浏览器不支持地理定位功能')
      }else{
        alert(`拒绝位置请求无法提供服务`);
      }
      
      this.getCurLocation()
    },

    getCurrentLocation() {
      axios({
        url: "/foo/ws/location/v1/network", //接口地址（代理把起码路径去掉）
        method: "post", //接口规定，只能用get
        async: true, //异步
        params: { key: "GUHBZ-5GF3I-BQLGX-UL4FA-QQO6V-ELFFG", output: "jsonp" }, //参数格式必须用到output传参为jsonp，否则会报跨域问题
        responseType: "jsonp", //跨域，必须用到jsonp
      }).then((res) => {
        // var ip = res.data.replace("QQmap&&QQmap(", "").replace(");", "");
        console.log(res);
        // alert(res)
        // let data = JSON.parse(ip).result.location;
        // this.lat = data.lat;
        // this.lon = data.lng;
        // this.getLocationCN()
      });
    },
    getLocationCN() {
      this.columns = [];
      // this.showLocation = true
      axios({
        url: "foo/ws/geocoder/v1/", //接口地址（代理把起码路径去掉）
        method: "get", //接口规定，只能用get
        async: true, //异步
        params: {
          key: "P65BZ-646CH-JLEDY-W2VMD-RJAMZ-RDFWX",
          location: this.lat + "," + this.lon,
          get_poi: 1,
          poi_options: "policy=4;radius=500",
        }, //参数格式必须用到output传参为jsonp，否则会报跨域问题
        responseType: "jsonp", //跨域，必须用到jsonp
      }).then((res) => {
        let data = JSON.parse(res.data).result;
        this.address2 = data.formatted_addresses.recommend;
        this.address = data.formatted_addresses.recommend;
        data.pois.forEach((ele) => {
          this.columns.push(ele.title);
        });
        console.log(data);
      });
    },
    changeData() {},
    isValidPhoneNumber(phoneNumber) {
      // 正则表达式匹配中国大陆手机号码
      const regex = /^1[3-9]\d{9}$/;
      return regex.test(phoneNumber);
    },
    checkMobileModel() {
      var userAgent = navigator.userAgent;
      if (/iPhone/.test(userAgent)) {
        return "iPhone";
      } else if (/Android/.test(userAgent)) {
        // 根据不同的 Android 手机型号匹配
        if (/Samsung/.test(userAgent)) {
          return "Samsung";
        } else if (/Huawei/.test(userAgent)) {
          return "Huawei";
        } else {
          return "Android";
        }
        // Add more conditions for other Android phone models
      }
      return "Unknown";
    },
    submit() {
      if (!this.name) {
        this.$message.error("请填写姓名");
        return;
      }
      if (!this.phone) {
        this.$message.error("请填写手机号");
        return;
      }
      let isPhone = this.isValidPhoneNumber(this.phone);
      if (!isPhone) {
        this.$message.error("手机号格式错误");
        return;
      }
      console.log(this.checkMobileModel());
      this.ip = navigator.userAgent;
      for (var i = 0; i < this.objInfo.length; i++) {
        let ele = this.objInfo[i];
        let keys = ele.vModel;

        if (this.form[keys]) {
          ele.defaultValue = this.form[keys];
          console.log(ele);
        } else {
          if (ele.required) {
            if (ele.tag == "el-checkbox-group" || ele.tag == "el-upload") {
              if (ele.defaultValue.length > 0) {
                console.log("有数据");
              } else {
                this.$message.error({
                  message: "请确认必填项是否填写",
                  offset: 100,
                });
                console.log(ele);
                return;
              }
            } else {
              this.$message.error({
                message: "请确认必填项是否填写",
                offset: 100,
              });
              console.log(ele.tag, "必填");
              return;
            }
          }
        }
      }
      let params = {
        taskId: this.taskId,
        selectedId: this.selected,
        userId: this.userId,
        name: this.name,
        phone: this.phone,
        templateId: this.templateId,
        address: this.address,
        lat: this.lat,
        lon: this.lon,
        multiName: this.dataList.templateName,
        multiContent: JSON.stringify(this.objInfo),
        taskCategory: this.taskCategory,
        equipmentNo: this.checkMobileModel(),
        longitude: this.lon,
        latitude: this.lat,
        address_name: this.address,
        userAgent:this.userAgent,
      };
      this.$api.fIllSubmit(params).then((res) => {
        console.log("提交", res);
        if (res.code == 200) {
          this.$message.success("提交成功");
          setTimeout(() => {
            this.$router.push("/mySuccess");
          }, 1000);
        } else {
          this.$message.error(res.msg);
          setTimeout(() => {
            window.close();
          }, 1000);
        }
      });
      console.log(params);
    },
    uploadImage(item, val) {
      console.log(item, val, "上传");
      this.objInfo.forEach((ele) => {
        if (ele.vModel == item.vModel) {
          ele.defaultValue = ele.defaultValue ? ele.defaultValue : [];
          ele.defaultValue.push(val);
          console.log("val", val);
        }
      });

      console.log(this.objInfo);
    },
    delImage(item, val) {
      this.objInfo.forEach((ele) => {
        if (ele.vModel == item.vModel) {
          ele.defaultValue = val;
        }
      });
      console.log(this.objInfo);
    },
    getParams() {
      var url = window.location.href;
      var querys = url.substring(url.indexOf("?") + 1).split("&");

      for (var i = 0; i < querys.length; i++) {
        var temp = querys[i].split("=");
        if (temp.length < 2) {
          this.result[temp[0]] = "";
        } else {
          this.result[temp[0]] = temp[1];
        }
        this.templateId = this.result.templateId || 10;
        this.selected = this.result.selected || 29;
        this.taskId = this.result.taskId || 25;
        this.userId = this.result.userId || 134;
        this.taskCategory = this.result.short || 110;
      }
      
      this.debounce(this.judgmentNumber(this.taskId, this.selected, this.userId,this.userAgent),300)
    },
    debounce(fn, delay) {
      let timer = null;
      return function () {
        let context = this;
        let args = arguments;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context, args);
        }, delay);
      };
    },
    getLocation1() {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            (this.lat = position.coords.latitude),
              (this.lon = position.coords.latitude),
              console.log(this.location);
          },
          (error) => {
            console.error("获取位置信息失败:", error);
            console.log(
              "无法获取位置信息，请确保您已授权访问位置信息并且设备的定位服务已开启。"
            );
          }
        );
      } else {
        console.log("您的浏览器不支持地理定位功能。");
      }
    },
    getList() {
      this.$api.qRUrlExist(this.templateId).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.dataList = res.data;
          this.objInfo = JSON.parse(res.data.templateContent);
          this.info = res.data.templateContent;
        }
      });
    },
    getComponentProps(item) {
      // const { placeholder, tag, tagIcon, renderKey, layout, vModel, label, required, ...props } = item;
      // return props;
      //   重写
      const props = { ...item };

      // console.log(props, 11);
      // delete props.placeholder; // 删除 placeholder 属性
      // delete props.defaultValue;
      // 删除其他不需要绑定的属性
      // delete props.tag;
      // delete props.tagIcon;
      // delete props.renderKey;
      // delete props.layout;
      // delete props.vModel;
      // delete props.label;
      // delete props.required;

      // console.log("delete last let", props);
      return props;
    },
    handelCheck(item, val) {
      console.log(item, val);
      this.objInfo.forEach((ele) => {
        if (ele.vModel == item.vModel) {
          ele.defaultValue = val;
        }
      });
      console.log(this.objInfo);
    },
    judgmentNumber(taskId, selected, userId,userAgent) {
      this.$api.judgmentNumber(taskId, selected, userId,userAgent).then((res) => {
        console.log(res, "chaxun");
        if (res.code == 200) {
          if (res.data.state == 0) {
            this.getList();
            this.getCurLocation();
          } else {
            this.$router.push("/myError?title=" + res.data.message);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.el-form {
  min-height: 80vh;
  min-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  // padding: 20px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
}

.el-form-item {
  // display: flex;
  align-items: center;
  margin-bottom: 20px;

  ::v-deep .el-form-item__label {
    flex-basis: 15%;
    text-align: left;
    margin-right: 10px;
    width: 100%;
  }

  ::v-deep .el-form-item__content {
    flex-basis: 85%;
    ::v-deep .el-input,
    ::v-deep .el-input__inner {
      width: 100%;
    }
  }
}
.myform {
  ::v-deep .el-form {
    padding: 20px;
    box-sizing: border-box;
  }
}
.templateName {
  margin-bottom: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}
::v-deep .el-form-item .el-form-item__content {
  text-align: left;
}
.btn {
  margin-top: 20px;
  width: 100%;
  margin-bottom: 50px;
  ::v-deep .el-button {
    width: 100%;
  }
}
.location {
  display: flex;
}
</style>
