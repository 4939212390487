<!--
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2024-03-13 11:35:35
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2024-03-16 17:01:34
 * @FilePath: \53-yiliaoheguiScanQRCode\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
