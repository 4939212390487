/*
 * @Author: hanchunxuan 1627047609@qq.com
 * @Date: 2024-03-13 11:35:35
 * @LastEditors: hanchunxuan 1627047609@qq.com
 * @LastEditTime: 2024-03-14 17:18:37
 * @FilePath: \53-yiliaoheguiScanQRCode\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import api from './api' // 确保 './api' 路径正确，且该模块导出了 API 方法
import router from './router/router' // 确保 './router/router' 路径正确
import fullscreen from 'vue-fullscreen'
import { Button } from 'vant';
import { Picker } from 'vant';
import { Popup } from 'vant';
import { Uploader } from 'vant';

Vue.use(Uploader);

Vue.use(Popup);
Vue.use(Picker);
Vue.use(Button)
Vue.use(fullscreen)

Vue.prototype.$api = api // 将 API 方法添加到 Vue 原型上
Vue.use(ElementUI)

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
