import request from '@/util/axios'

// const host ='http://192.168.31.181:8080'
const api = {
    // 示例 API 方法
    getUserInfo(params) {
        return request({
            url: '/api/user/info',
            method: 'get',
            params
        })
    },
    // 另一个 API 方法
    updateUser(data) {
        return request({
            url: '/api/user/update',
            method: 'post',
            data
        })
    },
    //获取模板
    getTemDetail(id) {
        return request({
            url: '/api/system/template/'+id,
            method: 'get',
        })
    },
    //获取模板
    qRUrlExist(id) {
        return request({
            url: '/api/system/qrCode/qRUrlExist?templateId='+id,
            method: 'get',
        })
    },
    //提交任务
    fIllSubmit(data) {
        return request({
            url: '/api/system/task/fIllSubmit',
            method: 'post',
            data
        })
    },
    //提交任务
    uploadImage(data) {
        return request({
            url: '/api/system/upload/uploadImage',
            method: 'post',
            data
        })
    },
    //判断任务可不可以填写
    judgmentNumber(taskId,selectedId,userId,userAgent) {
        return request({
            url: '/api/system/qrCode/judgmentNumber?taskId='+taskId+'&selectedId='+selectedId+'&userId='+userId +'&userAgent='+userAgent,
            method: 'get',
       
        })
    },
}

export default api
